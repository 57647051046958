import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

export default class Avances extends Component {
  render() {
    return (
      <>
        <Header />
        <h1>AVANCES</h1>
        <p>
          Lorem ipsum dolor sit cuchuflí barquillo bacán jote gamba listeilor po cahuín, luca melón
          Chorrocientos pituto ubicatex huevo duro bolsero cachureo el hoyo del queque en cana
          huevón el año del loly hacerla corta impeque de miedo quilterry la raja longi ñecla. Hilo
          curado rayuela carrete quina guagua lorea piola ni ahí.
        </p>
        <Footer />
      </>
    );
  }
}
